<template>
  <div>
    <!-- 编辑记录-用户信息 -->
    <div class="content1">
      <div class="all">
        <div class="table" >
          <div style="display:flex;margin-top:20px">
            <el-button  class="SearchBtn" size="small" @click="backtrack">返回</el-button>
          </div>
          <!--数据表格-->
          <div>
            <el-form class="form_box">
              <div class="front_box">

                <div class="particulars">
                  <span class="img"></span>
                  <span class="titles">编辑前数据</span>
                </div>
                <div class="box_info">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item >
                        <span slot="label"><span style="color: #303133;font-size: 16px;font-weight: 900;">用户信息</span></span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="户主姓名：" prop="positionName" label-width='120px'>
                        {{ oldData.residentName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="户主电话：" prop="positionName" label-width='120px'>
                        {{ oldData.mobile }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="身份证号：" prop="positionName" label-width='120px'>
                        {{ oldData.idnumber }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="用户分类：" prop="positionName" label-width='120px'>
                        {{ oldData.userTypeName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="片区：" prop="positionName" label-width='120px'>
                        {{ oldData.addrName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="地址：" prop="positionName" label-width='120px'>
                        {{ oldData.address }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="负责部门：" prop="positionName" label-width='120px'>
                        {{ oldData.deptName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="表编号：" prop="positionName" label-width='120px'>
                        {{ oldData.sheetSteel }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="燃气表类型：" prop="positionName" label-width='120px'>
                        {{ oldData.tableTypeName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="表向：" prop="positionName" label-width='120px'>
                        {{ oldData.tableDirectionName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="燃气表厂家：" prop="positionName" label-width='120px'>
                        {{ oldData.tableVenderName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="表封号：" prop="positionName" label-width='120px'>
                        {{ oldData.tableTitles }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="开户日期：" prop="positionName" label-width='120px'>
                        {{ oldData.openDate }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="所接炉具：" prop="positionName" label-width='120px'>
                        {{ oldData.receiveStove }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- <el-row>
                    <el-col :span="24">
                      <el-form-item label="对接系统：" prop="positionName" label-width='120px'>
                        {{ oldData.tableSystem? oldData.tableSystem==1?'卡表系统':'营收系统':'' }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="识别号：" prop="positionName" label-width='120px'>
                        {{ oldData.identifyNumber }}
                      </el-form-item>
                    </el-col>
                  </el-row> -->
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="备注：" prop="positionName" label-width='120px'>
                        {{ oldData.remark }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="安检联系人：" prop="positionName" label-width='120px'>
                        {{ oldData.contactName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="联系电话：" prop="positionName" label-width='120px'>
                        {{ oldData.contactMobile }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="是否为租户：" prop="positionName" label-width='120px'>
                        {{ oldData.islessee == 0 ? '否' : (oldData.islessee==1?'是':'')  }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="front_box">

                <div class="particulars">
                  <span class="img" style="border: 1px solid #E93838"></span>
                  <span class="titles">编辑后数据</span>
                </div>
                <div class="box_info">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item >
                        <span slot="label"><span style="color: #303133;font-size: 16px;font-weight: 900;">用户信息</span></span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="户主姓名：" prop="residentName" label-width='120px'>
                        <span :class="newData.residentName==oldData.residentName?'':'red'">{{ newData.residentName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="户主电话：" prop="mobile" label-width='120px'>
                        <span :class="newData.mobile==oldData.mobile?'':'red'">{{ newData.mobile }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="身份证号：" prop="idnumber" label-width='120px'>
                        <span :class="newData.idnumber==oldData.idnumber?'':'red'">{{ newData.idnumber }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="用户分类：" prop="userType" label-width='120px'>
                        <span :class="newData.userType==oldData.userType?'':'red'">{{ newData.userTypeName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="片区：" prop="addrName" label-width='120px'>
                        <span :class="newData.addrName==oldData.addrName?'':'red'">{{ newData.addrName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="地址：" prop="address" label-width='120px'>
                        <span :class="newData.address==oldData.address?'':'red'">{{ newData.address }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="负责部门：" prop="deptName" label-width='120px'>
                        <span :class="newData.deptName==oldData.deptName?'':'red'">{{ newData.deptName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="表编号：" prop="sheetSteel" label-width='120px'>
                        <span :class="newData.sheetSteel==oldData.sheetSteel?'':'red'">{{ newData.sheetSteel }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="燃气表类型：" prop="tableType" label-width='120px'>
                        <span :class="newData.tableType==oldData.tableType?'':'red'">{{ newData.tableTypeName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="表向：" prop="tableDirectionName" label-width='120px'>
                        <span :class="newData.tableDirectionName==oldData.tableDirectionName?'':'red'">{{ newData.tableDirectionName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="燃气表厂家：" prop="tableVender" label-width='120px'>
                        <span :class="newData.tableVender==oldData.tableVender?'':'red'">{{ newData.tableVenderName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="表封号：" prop="tableTitles" label-width='120px'>
                        <span :class="newData.tableTitles==oldData.tableTitles?'':'red'">{{ newData.tableTitles }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="开户日期：" prop="openDate" label-width='120px'>
                        <span :class="newData.openDate==oldData.openDate?'':'red'">{{ newData.openDate }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="所接炉具：" prop="receiveStove" label-width='120px'>
                        <span :class="newData.receiveStove==oldData.receiveStove?'':'red'">{{ newData.receiveStove }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- <el-row>
                    <el-col :span="24">
                      <el-form-item label="对接系统：" prop="tableSystem" label-width='120px'>
                        <span :class="newData.tableSystem==oldData.tableSystem?'':'red'">{{ newData.tableSystem?newData.tableSystem==1?'卡表系统':'营收系统':'' }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="识别号：" prop="identifyNumber" label-width='120px'>
                        <span :class="newData.identifyNumber==oldData.identifyNumber?'':'red'">{{ newData.identifyNumber }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row> -->
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="备注：" prop="remark" label-width='120px'>
                        <span :class="newData.remark==oldData.remark?'':'red'">{{ newData.remark }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="安检联系人：" prop="contactName" label-width='120px'>
                        <span :class="newData.contactName==oldData.contactName?'':'red'">{{ newData.contactName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="联系电话：" prop="contactMobile" label-width='120px'>
                        <span :class="newData.contactMobile==oldData.contactMobile?'':'red'">{{ newData.contactMobile }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="是否为租户：" prop="islessee" label-width='120px'>
                        <span :class="newData.islessee===oldData.islessee?'':'red'">{{ newData.islessee == 0? '否' : (newData.islessee==1?'是':'') }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import {userUpdateDetails} from '../../../RequestPort/peopleUser/people'
export default {
  name: "lockout",
  data(){
    return{
      newData:{

      },
      oldData:{

      }
    }
  },
  mounted() {
    this.loadList()
  },
  methods:{
    /**
     * 返回
     */
    backtrack(){
      this.$router.back(-1)
    },
    /**
     * 获取列表
     */
    loadList(){
      userUpdateDetails({recordId:this.$route.query.id}).then(res=>{
        this.newData = res.data.newData
        this.oldData = res.data.oldData
      })
    }
  }
}
</script>

<style lang="less" scoped>
.red{
  color: red;
}
.SearchBtn{
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  overflow: auto;
  height: calc(100vh - 194px);
  background-color: #ffffff;
}
.particulars {
  display: flex;
  flex-direction: row;
  align-items:center;
  height: 40px;
  position: relative;
  .img {
    border: 1px solid #3f74d5;
    height: 16px;
    margin-right: 9px;
  }
  // 标题文字
  .titles{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight:900;
    color: #303133;
  }
}
.el-form-item{
  margin: 0;
  ::v-deep.el-form-item__label{
    text-align: left;
  }
}
.form_box{
  display: flex;
  flex-direction: row;
}
// 数据前
.front_box{
  width: 778px;
  margin: 20px 30px;
  .box_info{
    padding-left: 29px;
  }
}
</style>